import React from 'react'

import avatarSrc from '../../assets/tobias_kaechele_01.png'
import SocialMediaBar from '../SocialMediaBar';
import Settings from '../../utils/Settings';
import { ArticleInfo } from '../../utils/ArticleInfos';

interface Props {
  urlToShare: string,
  articleInfos: ArticleInfo
}

interface State {

}

export default class BlogHeaderRenderer extends React.Component<Props, State> {
  render() {
    const { urlToShare, articleInfos } = this.props

    return (
      <>
        <div className="container p-0 mt-5">
          {articleInfos.date ? <p className="m-0 text-secondary" style={Sty.dateText}><small>{articleInfos.date}</small></p> : undefined}
          <h1 className="m-0 mb-1 header text-primary" style={Sty.headerText}>{articleInfos.title}</h1>
          {articleInfos.subtitle ? <h4 className="text-primary" style={Sty.subheaderText}>{articleInfos.subtitle}</h4> : undefined}
        </div>

        <div className="container p-0 mt-5 mb-5">
          <div className="d-flex flex-direction-row align-items-center">
            <img className="mr-2" src={avatarSrc} alt={Settings.author} style={{ width: '4em' }} />
            <div style={Sty.authorText}>By {Settings.author}</div>
            <SocialMediaBar urlToShare={urlToShare} className="ml-auto" title={articleInfos.title} short={articleInfos.short} urlToMedium={articleInfos.medium} />
          </div>
        </div>
      </>
    );
  }
}

//const LIGHT_GRAY = '#C8CECD'

const Sty: { [id: string]: React.CSSProperties; } = {

  imageContainer: {
  },
  button: {
    borderRadius: "20px",
    paddingTop: ".1rem",
    paddingBottom: ".25rem",
    paddingLeft: "1em",
    paddingRight: "1em"
  },
  container: {
    marginBottom: "10vh",
  },
  headerText: {
    fontFamily: 'Asap',
    fontWeight: 600,
    lineHeight: "1.05em",
  },
  subheaderText: {
    fontFamily: 'Asap',
    fontWeight: 400,
    lineHeight: "1.05em",
  },
  dateText: {
    opacity: 0.7,
    fontFamily: 'Roboto',
    fontWeight: 400,

  },
  authorText: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: "1.05em",
  },
}
