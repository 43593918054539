import React from 'react'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";


import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import FaIconTwitter from './icons/FaIconTwitter'
import FaIconFacebook from './icons/FaIconFacebook'
import FaIconLinkedin from './icons/FaIconLinkedin'
import Settings from '../utils/Settings';
import FaIconMedium from './icons/FaIconMedium';

interface Props {
  className?: string,
  size?: SizeProp,
  urlToShare: string,
  title?: string,
  short?: string,
  hashtags?: string[],
  urlToMedium?: string
}

interface State {

}

const DEFAULT_SIZE: SizeProp = "lg"
const DEFAULT_BOOTSTRAP_MARGIN_CLASS = ""
const LEFT_MARGIN = "0.7em";

export default class SocialMediaBar extends React.Component<Props, State> {

  renderMediumLink(size: any) {
    if (!this.props.urlToMedium) {
      return null;
    }


    return (<a href={this.props.urlToMedium} style={{ color: "inherit", marginLeft: LEFT_MARGIN }}><FaIconMedium className={DEFAULT_BOOTSTRAP_MARGIN_CLASS} size={size} /></a>)
  }

  render() {
    const size = this.props.size || DEFAULT_SIZE
    const url_to_share = this.props.urlToShare

    return (
      <div className={this.props.className}>
        <TwitterShareButton
          url={url_to_share}
          title={this.props.title}
          hashtags={this.props.hashtags}
          via={Settings.twitterHandle}
        >
          <FaIconTwitter className={DEFAULT_BOOTSTRAP_MARGIN_CLASS} size={size} />
        </TwitterShareButton>
        <FacebookShareButton url={url_to_share} style={{ marginLeft: LEFT_MARGIN }}>
          <FaIconFacebook className={DEFAULT_BOOTSTRAP_MARGIN_CLASS} size={size} />
        </FacebookShareButton>
        <LinkedinShareButton url={url_to_share} title={this.props.title} summary={this.props.short} source="www.TobiasKaechele.de" style={{ marginLeft: LEFT_MARGIN }}>
          <FaIconLinkedin className={DEFAULT_BOOTSTRAP_MARGIN_CLASS} size={size} />
        </LinkedinShareButton>
        {this.renderMediumLink(size)}
      </div>
    );
  }
}
