import React from 'react';
import { graphql } from 'gatsby';

import SiteFooter from '../components/SiteFooter';
import Navigation from '../components/Navigation';
import Settings from '../utils/Settings';
import BlogHeaderRenderer from '../components/markdown/BlogHeaderRenderer';
import SocialMediaBar from '../components/SocialMediaBar';
import { ArticleInfo, MarkdownRemarkNode } from '../utils/ArticleInfos';
import PageContainer from '../components/PageContainer';
import { OpenGraphImage } from '../components/Meta';

import './prism-xonokai.css' // https://github.com/PrismJS/prism-themes
import './blog.css'

interface PageTemplateProps {
  data: Data
}

interface State { }

export default class Blog extends React.Component<PageTemplateProps, State> {
  urlToShare: string
  sharingImage: OpenGraphImage
  article: ArticleInfo
  articleHtml: string

  constructor(props: PageTemplateProps) {
    super(props)

    this.article = ArticleInfo.from(this.props.data.markdownRemark)
    this.articleHtml = this.props.data.markdownRemark.html || ''
    this.urlToShare = Settings.getAbsoluteUrl(this.article.slug, "index.html")

    this.sharingImage = {
      src: Settings.getAbsoluteUrl(this.article.slug, Settings.defaultSharingThumbnail.name),
      type: Settings.defaultSharingThumbnail.type,
      width: Settings.defaultSharingThumbnail.width,
      height: Settings.defaultSharingThumbnail.height,
    }
  }

  render() {
    return (
      <PageContainer
        subtitle={this.article.title}
        description={this.article.short}
        keywords={this.article.keywords}
        sharingImage={this.sharingImage}
        sharingUrl={this.urlToShare}
      >
        <Navigation />
        <hr className="m-0 p-0" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <BlogHeaderRenderer articleInfos={this.article} urlToShare={this.urlToShare} />

              <div
                className="blog-content"
                dangerouslySetInnerHTML={{ __html: this.articleHtml || '' }}
              />

              <div className="container mt-5 mb-5">
                <div className="d-flex flex-direction-row justify-content-center align-items-center">
                  <SocialMediaBar urlToShare={this.urlToShare} title={this.article.title} short={this.article.short} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SiteFooter />
      </PageContainer>
    );
  }
}

interface Data {
  markdownRemark: MarkdownRemarkNode
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date
        short
        subtitle
        medium
        keywords
      }
    }
  }
`
